import DSTypography from "../system-design/DSTypography";
import background from "./assets/background.png";
import logo from "./assets/logo-01.png";
import DSAppBar from "../system-design/DSAppBar";
import { useHistory } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useGiftContext } from "./Context/GiftContextProvider";
import DSBox from "../system-design/DSBox";
import giftClient from "../../api/gift";
import { isMobile } from "react-device-detect";
import { KLYDO_BLACK } from "../../consts/colors";
import DSPrimaryButton from "../system-design/DSPrimaryButtom";
import useOrderQuery from "./hooks/useOrderQuery";

const GiftHomePage = () => {
  const { search } = useHistory();
  const { setKlydoGiftProps, klydoGiftProps, setSelectedKlydoGreet } =
    useGiftContext();
  const { data: orderInfo } = useOrderQuery(search.orderId as string);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGift = async () => {
      const orderId = search.orderId;
      if (orderId) {
        const gift = await giftClient.getOrderGift(orderId as string);
        if (gift) {
          setKlydoGiftProps(gift);
          setSelectedKlydoGreet(gift.klydo);
          navigate(`/gift/done`);
        }
      }
    };
    fetchGift();
  }, []);

  useEffect(() => {
    const orderId = search.orderId;
    const clockId = search.clockId;
    setKlydoGiftProps({
      ...klydoGiftProps,
      orderId: orderId as string,
      clockId: clockId as string,
    });
  }, []);

  const handleCreateGreet = () => {
    navigate(`/gift/wizard?step=occasion`);
  };
  const prsonalInfo = orderInfo?.data?.first_name
    ? `${orderInfo?.data?.first_name} ${orderInfo?.data?.last_name}`
    : "";
  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundColor: KLYDO_BLACK,
        backgroundSize: isMobile ? "cover" : "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <DSAppBar
        position="fixed"
        color="transparent"
        sx={{
          paddingTop: { xs: 3, md: 5 },
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          boxShadow: "none",
        }}
      >
        <img width={isMobile ? "150" : "250"} src={logo} />
      </DSAppBar>
      <DSBox
        sx={{
          position: "absolute",
          bottom: 0,
          marginBottom: { xs: 3, md: 10 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {prsonalInfo && (
          <DSTypography
            lineHeight={1}
            fontWeight={600}
            fontSize={"40px"}
            fontFamily={"Gilroy-Bold"}
            color="#FFFFFF"
            textAlign="center"
            maxWidth={"300px"}
          >
            {`Hi ${prsonalInfo},`}
          </DSTypography>
        )}
        <DSTypography
          lineHeight={1.2}
          fontWeight={600}
          fontSize={"40px"}
          fontFamily={"Gilroy-Bold"}
          color="#FFFFFF"
          textAlign="center"
          mb={2}
          maxWidth={"300px"}
        >
          {`Welcome to KlydoGreet`}
        </DSTypography>

        <DSTypography
          fontSize={"16px"}
          fontWeight={400}
          fontFamily={"Gilroy-Medium"}
          color="#FFFFFF"
          textAlign="center"
          mb={3}
          maxWidth={"300px"}
        >
          Add a personal touch to your gift with your choice of animated
          greeting card and a custom note.
        </DSTypography>
        {/* {(klydoGiftProps?.orderId || klydoGiftProps?.clockId) && ( */}
        <DSPrimaryButton
          sx={{ width: "240px", fontSize: "16px" }}
          onClick={handleCreateGreet}
        >
          Create a KlydoGreet
        </DSPrimaryButton>
        {/* )} */}
      </DSBox>
    </div>
  );
};

export default GiftHomePage;
