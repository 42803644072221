import DSImageList from "../system-design/DSImageList";
import DSImageListItem from "../system-design/DSImageListItem";
import { useTheme } from "@mui/material";
import DSImageListItemBar from "../system-design/DSImageListItemBar";
import { useGiftContext } from "./Context/GiftContextProvider";
import DSBox from "../system-design/DSBox";
import { GiftCategory } from "../../api/gift";
import { KLYDO_YELLOW } from "../../consts/colors";
import { isMobile } from "react-device-detect";

const GiftOccasion = () => {
  const { setSelectedOccasion, selectedOccasion, giftCategories } =
    useGiftContext();

  const handleSelectOccasion = (giftCategory: GiftCategory) => {
    setSelectedOccasion(giftCategory);
  };

  const theme = useTheme();

  return (
    <DSBox
      sx={{
        paddingTop: { xs: 1, md: 5 },
        justifyContent: "center",
        display: "flex",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <DSImageList
        cols={isMobile ? 2 : 5}
        gap={isMobile ? 10 : 20}
        sx={{
          width: { xs: 300, md: 800 },
        }}
      >
        {giftCategories?.map((giftCategory) => (
          <DSImageListItem
            key={giftCategory.name}
            onClick={() => handleSelectOccasion(giftCategory)}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              display: "flex",
              cursor: "pointer",
            }}
          >
            <img
              src={`${giftCategory.categoryImageUrl}`}
              alt={giftCategory.name}
              style={{
                objectFit: "none",
                border: `5px solid ${giftCategory.name === selectedOccasion?.name ? KLYDO_YELLOW : "transparent"}`,
                width: 120,
                height: 120,
                borderRadius: "20%",
                backgroundColor: "#FFFFFF",
              }}
            />
            <DSImageListItemBar
              position="below"
              title={giftCategory.name}
              sx={{
                "& .MuiImageListItemBar-title": {
                  fontSize: "15px",
                  fontWeight: "400",
                  fontFamily: "Gilroy-Bold",
                  color:
                    giftCategory.name === selectedOccasion?.name
                      ? KLYDO_YELLOW
                      : "inherit",
                },
              }}
            />
          </DSImageListItem>
        ))}
      </DSImageList>
    </DSBox>
  );
};

export default GiftOccasion;
