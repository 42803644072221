import { createContext, useContext, useEffect, useState } from "react";
import { Klydo } from "../../../context/klydo/KlydoTypes";
import { GiftCategory, KlydoGiftProps } from "../../../api/gift";
import useGiftCategories from "../hooks/useGiftCategories";
import axios from "axios";
import { parseCloudinaryUrl } from "../../../utils";
import { SplashScreen } from "../../../LoginView/SplashScreen";

export type GiftContextType = {
  selectedOccasion?: GiftCategory;
  selectedKlydoGreet?: Klydo;
  klydoGiftProps: KlydoGiftProps;
  setSelectedOccasion: React.Dispatch<React.SetStateAction<GiftCategory>>;
  setSelectedKlydoGreet: React.Dispatch<React.SetStateAction<Klydo>>;
  setKlydoGiftProps: React.Dispatch<React.SetStateAction<KlydoGiftProps>>;
  giftCategories: GiftCategory[];
};
const GiftContext = createContext<GiftContextType>({} as GiftContextType);

export const useGiftContext = () => useContext(GiftContext);

export const GiftContextProvider = ({ children }) => {
  const { data: giftCategories, isLoading } = useGiftCategories();
  const [initializing, setInitializing] = useState(true);
  const [selectedOccasion, setSelectedOccasion] = useState<GiftCategory>();
  const [selectedKlydoGreet, setSelectedKlydoGreet] = useState<Klydo>();
  const [klydoGiftProps, setKlydoGiftProps] = useState<KlydoGiftProps>();
  useEffect(() => {
    if (selectedKlydoGreet) {
      axios.get(
        parseCloudinaryUrl({
          url:
            selectedKlydoGreet.convertedLoopUrl || selectedKlydoGreet.loopUrl,
          isStatic: false,
        }),
      );
    }
  }, [selectedKlydoGreet]);

  useEffect(() => {
    if (klydoGiftProps?.senderImageUrl) {
      axios.get(klydoGiftProps.senderImageUrl);
    }
  }, [klydoGiftProps]);

  useEffect(() => {
    if (!isLoading) {
      Promise.all(
        giftCategories?.map((category) => {
          return Promise.all(
            category.klydos.map((klydo) => {
              return axios.get(
                parseCloudinaryUrl({
                  url: klydo.convertedLoopUrl || klydo.loopUrl,
                  isStatic: true,
                }),
              );
            }),
          );
        }),
      ).then(() => {
        setInitializing(false);
      });
    }
  }, [isLoading, giftCategories]);
  if (initializing) {
    return SplashScreen();
  }
  return (
    <GiftContext.Provider
      value={{
        selectedOccasion,
        selectedKlydoGreet,
        klydoGiftProps,
        setSelectedOccasion,
        setSelectedKlydoGreet,
        setKlydoGiftProps,
        giftCategories,
      }}
    >
      {children}
    </GiftContext.Provider>
  );
};
