import { Klydo } from "../../context/klydo/KlydoTypes";
import { parseCloudinaryUrl } from "../../utils";
import DSImageList from "../system-design/DSImageList";
import DSImageListItem from "../system-design/DSImageListItem";
import { useTheme } from "@mui/material";
import { useGiftContext } from "./Context/GiftContextProvider";
import DSBox from "../system-design/DSBox";
import { KLYDO_YELLOW } from "../../consts/colors";
import { isMobile } from "react-device-detect";

const KlydoImage = (klydo: {
  id: string;
  loopUrl: string;
  convertedLoopUrl: string;
}) => {
  const url = parseCloudinaryUrl({
    url: klydo.convertedLoopUrl || klydo.loopUrl,
    isStatic: true,
  });
  return (
    <img
      style={{ borderRadius: 50 + "%" }}
      loading="lazy"
      height={96}
      width={96}
      src={url}
      alt="klydo"
    />
  );
};
const GiftKlydoGreet = () => {
  const theme = useTheme();
  const { selectedKlydoGreet, setSelectedKlydoGreet, selectedOccasion } =
    useGiftContext();

  const handleSelectedKlydoGreet = (klydo: Klydo) => {
    setSelectedKlydoGreet(klydo);
  };

  return (
    <DSBox
      sx={{
        paddingTop: { xs: 1, md: 8 },
        justifyContent: "center",
        display: "flex",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <DSImageList
        cols={isMobile ? 2 : 5}
        gap={isMobile ? 15 : 50}
        sx={{
          width: { xs: 300, md: 850 },
        }}
      >
        {selectedOccasion?.klydos?.map((klydoGreet) => (
          <DSImageListItem
            key={klydoGreet.loopUrl}
            onClick={() => handleSelectedKlydoGreet(klydoGreet)}
            sx={{
              border: `4px solid ${selectedKlydoGreet?.id === klydoGreet.id ? KLYDO_YELLOW : "white"}`,
              borderRadius: "50%",
              width: 129,
              height: 120,
              cursor: "pointer",
            }}
          >
            {KlydoImage(klydoGreet)}
          </DSImageListItem>
        ))}
      </DSImageList>
    </DSBox>
  );
};

export default GiftKlydoGreet;
