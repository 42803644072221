import React, { useState, useEffect } from "react";
import DSMobileStepper from "../system-design/DSMobileStepper";
import steps from "./Steps/steps";
import { useNavigate } from "react-router-dom";
import { useHistory } from "../../utils";
// eslint-disable-next-line import/named
import { SxProps, Theme, useTheme } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckIcon from "@mui/icons-material/Check";
import logo from "./assets/logo-05-05.png";
import DSPaper from "../system-design/DSPaper";
import DSAppBar from "../system-design/DSAppBar";
import DSTypography from "../system-design/DSTypography";
import DSBox from "../system-design/DSBox";
import DSDialog from "../system-design/DSDialog";
import DSDialogTitle from "../system-design/DSDialogTitle";
import DSDialogContent from "../system-design/DSDialogContent";
import DSDialogActions from "../system-design/DSDialogActions";
import DSPrimaryButton from "../system-design/DSPrimaryButtom";
import { useGiftContext } from "./Context/GiftContextProvider";
import giftClient from "../../api/gift";
import { KLYDO_YELLOW, KLYDO_BLACK } from "../../consts/colors";
import { isIOS, isMobile } from "react-device-detect";
import DSSecondaryButton from "../system-design/DSSecondaryButton";
import DSPrimaryIconButton from "../system-design/DSPrimaryIconButton";
import DSSecondaryIconButton from "../system-design/DSSecondaryIconButton";

const GiftWizard = () => {
  const { setQueryParam, search } = useHistory();
  const { selectedOccasion, klydoGiftProps, selectedKlydoGreet } =
    useGiftContext();
  const step = search.step;
  const activeStep = steps.findIndex((s) => s.id === step);
  const theme = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search.step]);
  useEffect(() => {
    if (
      (activeStep === 1 && !selectedOccasion) ||
      (activeStep === 2 && !selectedKlydoGreet) ||
      (activeStep === 3 && !klydoGiftProps?.senderName)
    ) {
      navigate(`/gift`);
    }
  });
  const [finalDialogOpen, setFinalDialogOpen] = useState(false);

  const handleButtonDone = () => {
    if (!klydoGiftProps?.senderName) {
      alert("Your name is required");
      return;
    }
    setFinalDialogOpen(true);
  };

  const handleDone = async () => {
    setFinalDialogOpen(false);
    await giftClient.createGiftKlydo({
      ...klydoGiftProps,
      klydoId: selectedKlydoGreet?.id,
    });
    navigate(`/gift/done`, { replace: true });
  };

  const handleNext = () => {
    if (
      (activeStep === 0 && selectedOccasion) ||
      (activeStep === 1 && selectedKlydoGreet) ||
      (activeStep === 2 && klydoGiftProps?.senderName)
    ) {
      setQueryParam("step", steps[activeStep + 1].id, true);
    }
  };

  const handleBack = () => {
    setQueryParam("step", steps[activeStep - 1].id, true);
  };

  const buttonSx: SxProps<Theme> = {
    textTransform: "none",
    padding: 2,
    margin: 1,
    color: KLYDO_BLACK,
  };
  return (
    <>
      <DSDialog
        open={finalDialogOpen}
        onClose={() => setFinalDialogOpen(false)}
      >
        <DSDialogTitle>Final Confirmation</DSDialogTitle>
        <DSDialogContent>
          Clicking Yes will finalize all changes. You won’t be able to make
          further edits after confirming. Are you sure you want to continue?
        </DSDialogContent>
        <DSDialogActions>
          <DSSecondaryButton
            sx={{ fontSize: "15px" }}
            onClick={() => setFinalDialogOpen(false)}
          >
            Cancel
          </DSSecondaryButton>
          <DSPrimaryButton
            sx={{ fontSize: "15px" }}
            onClick={() => {
              handleDone();
            }}
          >
            Done
          </DSPrimaryButton>
        </DSDialogActions>
      </DSDialog>

      <DSPaper
        sx={{
          overflow: "hidden",
          minHeight: { xs: isIOS && isMobile ? 0 : "100vh", md: "100vh" },
        }}
      >
        <DSAppBar
          position="fixed"
          color="transparent"
          sx={{
            backgroundColor: theme.palette.background.paper,
            paddingY: 3,
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            boxShadow: "none",
          }}
        >
          <img width={isMobile ? "150" : "250"} src={logo} />
        </DSAppBar>
        <DSBox
          sx={{
            overflow: "auto",
            backgroundColor: theme.palette.background.paper,
            paddingTop: { xs: 11, md: 13 },
            height: "100vh",
            paddingBottom: { xs: 10, md: 20 },
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "start",
            hight: "100vh",
          }}
        >
          <DSTypography
            lineHeight={1}
            fontStyle={"bold"}
            fontSize={isMobile ? 35 : 47.52}
            fontWeight={400}
            fontFamily={"Gilroy-Bold"}
            maxWidth={isMobile ? "270px" : "1080px"}
          >
            {steps[activeStep]?.title}
          </DSTypography>
          <DSBox py={0.8} />
          <DSTypography
            fontSize={isMobile ? 15 : 18}
            fontWeight={400}
            fontFamily={"Gilroy-Medium"}
            maxWidth={isMobile ? "270px" : "1080px"}
          >
            {steps[activeStep]?.description}
          </DSTypography>
          {steps[activeStep]?.component}
        </DSBox>
        <DSMobileStepper
          variant="dots"
          position="bottom"
          steps={steps.length}
          activeStep={activeStep}
          sx={{
            width: { xs: "100%", md: 900 },
            margin: "auto",
            ".MuiMobileStepper-buttom": {
              backgroundColor: "#D9D9D9",
              margin: { xs: "5px" },
            },
            ".MuiMobileStepper-dot": {
              backgroundColor: "#D9D9D9",
              margin: { xs: "5px" },
            },
            ".MuiMobileStepper-dotActive": {
              backgroundColor: "#fac641",
            },
          }}
          nextButton={
            activeStep === 3 ? (
              <DSPrimaryIconButton
                onClick={() => {
                  handleButtonDone();
                }}
                sx={{
                  ...buttonSx,
                  width: { xs: 20, md: "60px" },
                  height: { xs: 20, md: "60px" },
                }}
              >
                <CheckIcon sx={{ fontSize: { xs: 20, md: 30 } }} />
              </DSPrimaryIconButton>
            ) : (
              <DSPrimaryIconButton
                onClick={() => handleNext()}
                disabled={
                  (activeStep === 0 && !selectedOccasion) ||
                  (activeStep === 1 && !selectedKlydoGreet) ||
                  (activeStep === 2 && !klydoGiftProps?.senderName)
                }
                sx={{
                  ...buttonSx,
                  width: { xs: 20, md: "60px" },
                  height: { xs: 20, md: "60px" },
                  "&:hover": {
                    backgroundColor: KLYDO_YELLOW,
                  },
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: { xs: 20, md: 30 } }} />
              </DSPrimaryIconButton>
            )
          }
          backButton={
            <DSSecondaryIconButton
              onClick={() => handleBack()}
              disabled={activeStep === 0 || activeStep === 4}
              sx={{
                ...buttonSx,
                width: { xs: 20, md: "60px" },
                height: { xs: 20, md: "60px" },
              }}
            >
              <ArrowBackIosNewIcon sx={{ fontSize: { xs: 20, md: 30 } }} />
            </DSSecondaryIconButton>
          }
        />
      </DSPaper>
    </>
  );
};
export default GiftWizard;
